
.adds-preview thead {
    margin-top: 12x;
}

.adds-preview thead th {
    font-weight: bold;
    text-decoration: underline;
}

.adds-table-date {
    width: 9%;
}

.adds-table-artist {
    width: 32%;
}

.adds-table-album {
    width: 32%;
}

.adds-table-media {
    width: 4%;
}

.adds-table-label {
    width: 23%;
}

.adds-table td {
    line-height: 1;
    font-size: 16px;
    vertical-align: top;
}

.adds-table td.adds-table-label span {
    line-height: 1.25;
}


@media screen {
    .adds-preview .page-title {
        font-size: 20px;
    }

    .adds-preview table {
        font-size: 16px;
        border-collapse: separate;
        border-spacing: 0px 16px;
    }
    
    .adds-preview thead {
        font-family: 'CarroisGothicSC', sans-serif;
        font-size: 18px;
    }

    .adds-preview th {

    }
}

@media print {

    @page {
        margin: 0.25in;
    }

    body {
        width: 8.5in;
        /*margin: 0in .1875in;*/
        margin: 0.25in .4375in;
    }

    .adds-preview .page-title {
        display:block;
        font-family: Helvetica, sans-serif;
        font-size: 20px;
        font-weight: bold;
        margin-left: 1px;
    }

    .adds-preview table {
        width: 100%;
        font-size: 10px;
        border-collapse: separate;
        border-spacing: 12px 18px;
    }

    .adds-preview thead {
        font-family: 'CarroisGothicSC', sans-serif;
        font-size: 16px;
    }

    .adds-preview .page  {
        page-break-after: always;
        page-break-after: always;
        -webkit-column-break-after: page;
                break-after: page;
    }
    
}

.label {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    /*grid-template-areas: "artist artist media" "album album album" "genre . date";*/

    font-size: 15px;
}

.label-artist {
    grid-area: artist;
    overflow: hidden;
    line-height: 1;
}
.label-genre { grid-area: genre; }
.label-media { grid-area: media; }
.label-album {
    grid-area: album;
    overflow: hidden;
    line-height: 1;
}
.label-date {
    grid-area: date;
    text-align: center;
    letter-spacing: 2px;
}

.label-artist,
.label-date {
    font-weight: bold;
}

@media screen {
    #tab-labels .col-sm-12 {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* 6.875px per 1/16th inch on a MacOS Retina screen */
    .labels-page {
        width: 900px;
        display: grid;
        grid-gap: 0 8px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: ". . .";
    }

    .label {
        width: 289px;
        height: 110px;
        border: 1px dotted black;
        border-radius: 5px;
        padding: 8px 8px;
    }

    .labels-preview .labels {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

@media print {

    @page {
        margin: 0.25in 0.25in 0; /* assuming default margins use */
    }

    body {
        width: 8.5in;
        margin-top: 0.35in; /* GOOD - tested with default margins */
        margin-bottom: 0.35in; /* GOOD - tested with default margins */
        margin-left: 0.045in; /* GOOD @ 0.055 - tested with default margins */
        margin-right: 0.045in; /* GOOD @ 0.055 - tested with default margins */
    }

    .labels-preview .label{
        width: 3.42545557022094in; /* approximation?? */
        height: 1.325in; /* GOOD */
        margin-right: .185in; /* GOOD - tested with default margins */
        padding: 0.075in 0.125in 0;

        float: left;

        text-align: left;
        overflow: hidden;
        font-family: Helvetica, sans-serif;
        font-size: 18px;

        outline: 1px none; /* outline doesn't occupy space like border does */
        border-radius: 5px;
    }

    .labels-preview .label:nth-child(3n) {
        margin-right: 0;
    }

    .labels-preview .labels-page  {
        clear: both!important;
        page-break-before: always;
        page-break-before: always;
        -webkit-column-break-before: page;
                break-before: page;
    }

    .labels-page:not(:first-child) {
        padding-top: 0.35in;
    }

    .col-sm-12 {
        padding: 0;
    }
}

@font-face {
    font-family: 'SabadoRegular';
    src: local('SabadoRegular'), url(/static/media/sabado.regular.8ebfb242.otf) format('opentype');
}

@font-face {
    font-family: 'CarroisGothicSC';
    src: local('CarroisGothicSC'), url(/static/media/CarroisGothicSC-Regular.6590dbcb.ttf) format('truetype');
}


@media screen {

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, #root, #root > div {
    height: 100%;
  }

  body {
    background-color: #eee9e9;
  }

  header {
    padding: 20px 32px 0;
    font-family: Helvetica, sans-serif;
  }

  header div {
    font-size: 16px;
  }

  input[type="date"]::-webkit-clear-button,
  input[type="date"]::-ms-clear {
      display: none;
  }

  .input-date {
    margin-left: 8px;
    display: inline-block;
    width: 180px;
  }

  .nav {
    padding: 15px 0 0 0;
    margin-left: -17px;
  }

  .nav-item a {
    cursor: pointer;
  }

  .nav-item .active {
    font-weight: bold;
  }

  .tab-content {
    padding: 15px 20px 15px 30px;
    background-color: white;
    min-height: 100%;

  }

  .a-inline {
    width: auto;
    display: inline;
  }

  .tab-content-header {
    line-height: 2;
  }

  .tab-content-header > h3,
  .tab-content-header > div.inline {
    vertical-align: middle;
    display: table-cell;
  }

  .tab-content-header input,
  .tab-content-header button {
    vertical-align: inherit;
  }

  .tab-content-header .input-file,
  .tab-content-header .input-date,
  .tab-content-header button {
    margin-left: 8px;
  }

  .tab-content-header button svg {
    margin-right: 8px;
  }

  .tab-content-header .form-check-label {
    margin-left: 30px;
  }

  .tab-content-header .form-check-input {
    margin-top: 0.5rem;
  }

  table {
    width: 100%;
  }

  table.import-adds th {
    padding-bottom: 10px;
  }

  table.import-adds tbody {
    line-height: 2;
  }

  table.import-adds td:focus,
  table.import-adds span:focus, {
    outline: 0;
  }

  table.import-adds .editing {
    min-width: 80%;
    font: 400 11px system-ui;
    line-height: 1.5;
  }

  .page-title {
    display: block;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

}


@media print {

    /* DEFAULT LAYOUT */
    html, body { display: block; height: auto; }
    header,.nav,.tab-content-header,hr { display: none; margin: 0; padding: 0; }
    .tab-content { padding: 0; }

    @page { size: letter portrait; }
}

