.label {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    /*grid-template-areas: "artist artist media" "album album album" "genre . date";*/

    font-size: 15px;
}

.label-artist {
    grid-area: artist;
    overflow: hidden;
    line-height: 1;
}
.label-genre { grid-area: genre; }
.label-media { grid-area: media; }
.label-album {
    grid-area: album;
    overflow: hidden;
    line-height: 1;
}
.label-date {
    grid-area: date;
    text-align: center;
    letter-spacing: 2px;
}

.label-artist,
.label-date {
    font-weight: bold;
}

@media screen {
    #tab-labels .col-sm-12 {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* 6.875px per 1/16th inch on a MacOS Retina screen */
    .labels-page {
        width: 900px;
        display: grid;
        grid-gap: 0 8px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: ". . .";
    }

    .label {
        width: 289px;
        height: 110px;
        border: 1px dotted black;
        border-radius: 5px;
        padding: 8px 8px;
    }

    .labels-preview .labels {
        display: flex;
        flex-direction: column;
    }
}

@media print {

    @page {
        margin: 0.25in 0.25in 0; /* assuming default margins use */
    }

    body {
        width: 8.5in;
        margin-top: 0.35in; /* GOOD - tested with default margins */
        margin-bottom: 0.35in; /* GOOD - tested with default margins */
        margin-left: 0.045in; /* GOOD @ 0.055 - tested with default margins */
        margin-right: 0.045in; /* GOOD @ 0.055 - tested with default margins */
    }

    .labels-preview .label{
        width: 3.42545557022094in; /* approximation?? */
        height: 1.325in; /* GOOD */
        margin-right: .185in; /* GOOD - tested with default margins */
        padding: 0.075in 0.125in 0;

        float: left;

        text-align: left;
        overflow: hidden;
        font-family: Helvetica, sans-serif;
        font-size: 18px;

        outline: 1px none; /* outline doesn't occupy space like border does */
        border-radius: 5px;
    }

    .labels-preview .label:nth-child(3n) {
        margin-right: 0;
    }

    .labels-preview .labels-page  {
        clear: both!important;
        page-break-before: always;
        break-before: page;
    }

    .labels-page:not(:first-child) {
        padding-top: 0.35in;
    }

    .col-sm-12 {
        padding: 0;
    }
}
