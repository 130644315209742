
@media print {

    /* DEFAULT LAYOUT */
    html, body { display: block; height: auto; }
    header,.nav,.tab-content-header,hr { display: none; margin: 0; padding: 0; }
    .tab-content { padding: 0; }

    @page { size: letter portrait; }
}
