
@media screen {

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, #root, #root > div {
    height: 100%;
  }

  body {
    background-color: #eee9e9;
  }

  header {
    padding: 20px 32px 0;
    font-family: Helvetica, sans-serif;
  }

  header div {
    font-size: 16px;
  }

  input[type="date"]::-webkit-clear-button,
  input[type="date"]::-ms-clear {
      display: none;
  }

  .input-date {
    margin-left: 8px;
    display: inline-block;
    width: 180px;
  }

  .nav {
    padding: 15px 0 0 0;
    margin-left: -17px;
  }

  .nav-item a {
    cursor: pointer;
  }

  .nav-item .active {
    font-weight: bold;
  }

  .tab-content {
    padding: 15px 20px 15px 30px;
    background-color: white;
    min-height: 100%;

  }

  .a-inline {
    width: auto;
    display: inline;
  }

  .tab-content-header {
    line-height: 2;
  }

  .tab-content-header > h3,
  .tab-content-header > div.inline {
    vertical-align: middle;
    display: table-cell;
  }

  .tab-content-header input,
  .tab-content-header button {
    vertical-align: inherit;
  }

  .tab-content-header .input-file,
  .tab-content-header .input-date,
  .tab-content-header button {
    margin-left: 8px;
  }

  .tab-content-header button svg {
    margin-right: 8px;
  }

  .tab-content-header .form-check-label {
    margin-left: 30px;
  }

  .tab-content-header .form-check-input {
    margin-top: 0.5rem;
  }

  table {
    width: 100%;
  }

  table.import-adds th {
    padding-bottom: 10px;
  }

  table.import-adds tbody {
    line-height: 2;
  }

  table.import-adds td:focus,
  table.import-adds span:focus, {
    outline: 0;
  }

  table.import-adds .editing {
    min-width: 80%;
    font: 400 11px system-ui;
    line-height: 1.5;
  }

  .page-title {
    display: block;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

}
