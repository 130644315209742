
.adds-preview thead {
    margin-top: 12x;
}

.adds-preview thead th {
    font-weight: bold;
    text-decoration: underline;
}

.adds-table-date {
    width: 9%;
}

.adds-table-artist {
    width: 32%;
}

.adds-table-album {
    width: 32%;
}

.adds-table-media {
    width: 4%;
}

.adds-table-label {
    width: 23%;
}

.adds-table td {
    line-height: 1;
    font-size: 16px;
    vertical-align: top;
}

.adds-table td.adds-table-label span {
    line-height: 1.25;
}


@media screen {
    .adds-preview .page-title {
        font-size: 20px;
    }

    .adds-preview table {
        font-size: 16px;
        border-collapse: separate;
        border-spacing: 0px 16px;
    }
    
    .adds-preview thead {
        font-family: 'CarroisGothicSC', sans-serif;
        font-size: 18px;
    }

    .adds-preview th {

    }
}

@media print {

    @page {
        margin: 0.25in;
    }

    body {
        width: 8.5in;
        /*margin: 0in .1875in;*/
        margin: 0.25in .4375in;
    }

    .adds-preview .page-title {
        display:block;
        font-family: Helvetica, sans-serif;
        font-size: 20px;
        font-weight: bold;
        margin-left: 1px;
    }

    .adds-preview table {
        width: 100%;
        font-size: 10px;
        border-collapse: separate;
        border-spacing: 12px 18px;
    }

    .adds-preview thead {
        font-family: 'CarroisGothicSC', sans-serif;
        font-size: 16px;
    }

    .adds-preview .page  {
        page-break-after: always;
        break-after: page;
    }
    
}
